@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "./pagination.scss";
@import "./navigation.scss";

// classes exptected to be removed in time
.viewCustomerTableRow {
	background: #f3f3f3;
	margin: 4px 0;
	border-radius: 4px;
	color: black;
}

.customerAddresses {
	background: #f3f3f3;
	border-radius: 5px;
	margin: 15px 0;
}

.customerInfo {
	background: #f3f3f3;
	border-radius: 5px;
}

.searchbar-input {
	border: none;
	border-bottom: 1px solid #e0e0e0;
}

// generic, applied no matter the theme
html,
body {
	min-height: 100%;
	background-color: white;
	font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4 {
	font-weight: 500;
}

a {
	color: black;
	text-decoration: none;
	background-color: transparent;
}

.BasicHRStyle {
	width: 20px;
	height: 5px;
}

.page-container-wrapper {
	width: 100%;
	height: 100%;
	background-color: white;
}

.alert-custom-warning {
	color: white;
	background-color: rgba(255, 166, 0, 0.233);
	border: 2px solid orange;
}

.alert-custom-danger {
	color: white;
	background-color: rgba(250, 53, 53, 0.233);
	border: 2px solid theme-color('warning');
}

.alert-custom-danger .alert-heading,
.alert-custom-warning .alert-heading {
	font-size: 15px;
}

.alert-custom-danger,
.alert-custom-warning {
	p {
		font-size: 13px;
	}
}

.overflow-x-scroll {
	overflow-x: auto;
}

.container-fluid {
	min-height: 100vh;
	width: 100%;
}

label {
	margin: 0;
}

.subtitles {
	display: flex;
	flex-direction: column;
}

.subtitle {
	margin: 5px 0;
	font-size: 13px;
}

.outlined {
	border: 3px solid var(--sub-green);
	border-radius: 5px;
}

.bg-darkgreen {
	background-color: rgb(30, 98, 73);
}

.navbar-toggler-icon {
	color: white;
}

.minh-150 {
	min-height: 150px;
}

.color-sub {
	color: theme-color('sub');
}

.color-dark {
	color: theme-color('grey') !important;
}

.bg-green {
	background: var(--sub-green);
}

.bg-orange {
	background: theme-color('sub');
}

.color-green {
	color: var(--sub-green);
}

.color-orange {
	color: theme-color('sub');
}

.color-red {
	color: theme-color('warning');
}

.highlightedRow {
	border-left: 3px solid theme-color('sub');
	transition: border-width 0.1s linear;
	display: block;
	padding: 5px 0;
}

.highlightedElement {
	border-left: 3px solid theme-color('sub');
}

.highlightedRow:hover {
	border-width: 10px;
}

.col-img-sm {
	max-height: 3em;
	vertical-align: top;
}

.col-img-md {
	max-height: 6em;
	vertical-align: top;
}

.select {
	min-width: 184.333px;
}

.brand-icon {
	max-height: 2em;
}

[role="button"]:not([disabled]) {
	cursor: pointer;
}

.page-link {
	color: black;
	border: none;
}

.page-item.active .page-link {
	background: black;
	border: none;
	border-radius: 10px;
}

#portal-body {
	display: flex;
	flex-direction: row;
	margin-top: 70px;
}

#portal-menu {
	flex: 0;
	> ul {
		list-style: none;
		padding: 0;
		white-space: nowrap;
	}
}

#portal-pages {
	flex: 1;
}
