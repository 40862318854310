$--standard-bg: #fff;
$--sub-green: rgb(44, 216, 153);
$--standard-grey: #2b2b36;
$--standard-lightergrey: #40404e;
$--standard-lightgrey: #2f2f3a;
$--standard-green: rgb(44, 216, 153);
$--standard-sub: rgb(232, 197, 106);
$--standard-warning:rgb(248, 52, 52);

$theme-colors: (
  "primary": $--standard-sub,
  "success": $--standard-sub,
  "background": $--standard-bg,
  "grey": $--standard-grey,
  "lightergrey": $--standard-lightergrey,
  "lightgrey": $--standard-lightgrey,
  "green":  $--standard-green,
  "sub": $--standard-sub,
  "warning": $--standard-warning,
  "dark": #363640,
  "black": #272727
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
